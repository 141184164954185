

























































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Document, IconsType } from "@/types";
import { mdiPaperclip } from "@mdi/js";
const ItemModule = namespace("ItemModule");
const AppModule = namespace("AppModule");
const size = 2 * 1024 * 1024;
const sizeText = "2 MiB";
@Component({
  components: {
    LabelDocument: () => import("@/components/Label/LabelDocument.vue")
  }
})
export default class StepperConsentUpload extends Vue {
  @ItemModule.State
  public signedConsent!: Document;
  @AppModule.Getter
  public smallScreen!: string;
  private truncateLength = 15;
  private uploading = false;
  private valid = false;
  private fileInputId = "file-input-consent";
  private consent: File | null = null;
  private accept = "application/pdf";
  private rules = [
    (t: File) => {
      if (!t) return true;
      if (t.type === this.accept) return true;
      else return this.$t("pages.item.stepper.rules.type-consent");
    },
    (t: File) => {
      if (!t) return true;
      if (t.size <= size) return true;
      else return this.$t("pages.item.stepper.rules.size", { s: sizeText });
    }
  ];
  private icons: IconsType = {
    file: mdiPaperclip
  };
  async removeConsent() {
    await this.$store.dispatch(
      "ItemModule/deleteDocument",
      this.signedConsent.id
    );
    this.$store.commit("ItemModule/SIGNED_CONSENT", null);
  }
  upload() {
    this.uploading = true;
    setTimeout(async () => {
      if (this.valid) {
        if (this.signedConsent) await this.removeConsent();
        const res = await this.$store.dispatch(
          "ItemModule/uploadConsent",
          this.consent
        );
        if (!res) this.$emit("error");
        else this.consent = null;
      }
      this.uploading = false;
    }, 0);
  }
  public onPrependInnerClick() {
    document.getElementById(this.fileInputId)?.click();
  }
}
